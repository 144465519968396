export function get_browser(){
	var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
	if(/trident/i.test(M[1])){
		tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
		return {name:'ie', version:(tem[1]||'')};
	}   
	if(M[1]==='Chrome'){
		tem = ua.match(/\bOPR\/(\d+)/);
		if(tem!=null){ 
			return {name:'opera', version:tem[1]}; }
	}   
	M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
	if((tem=ua.match(/version\/(\d+)/i))!=null) {
		M.splice(1,1,tem[1]);
	}
	//-
	return {
		name: M[0].toLowerCase(),
		version: M[1]
	};
}

export function detectMobile() {
	var ua = navigator.userAgent;
	if( ua.match(/Android/i) || ua.match(/webOS/i) || ua.match(/iPhone/i) || ua.match(/iPad/i) || ua.match(/iPod/i) || ua.match(/BlackBerry/i) || ua.match(/Windows Phone/i)
	){
		return true;
	}
	else {
		return false;
	}
}

export function detectIOS() {
	var ua = navigator.userAgent;
	if( ua.match(/iPhone/i) || ua.match(/iPad/i) || ua.match(/iPod/i)
	){
		return true;
	}
	else {
		return false;
	}
}

// - EXTRAS
export function getQueryVariable(variable){
   var query = window.location.search.substring(1);
   var vars = query.split("&");
   for (var i=0;i<vars.length;i++) {
           var pair = vars[i].split("=");
           if(pair[0] === variable){return pair[1];}
   }
   return(false);
}


// -
export function exist( _var ){
	return (typeof _var === 'undefined' || _var === undefined || _var === null) ? false : true;
}



// - Is window visible
var browserPrefixes = ['moz', 'ms', 'o', 'webkit'],
    isVisible = true; // internal flag, defaults to true

var onVisible, onHidden;

// get the correct attribute name
export function getHiddenPropertyName(prefix) {
  return (prefix ? prefix + 'Hidden' : 'hidden');
}

// get the correct event name
export function getVisibilityEvent(prefix) {
  return (prefix ? prefix : '') + 'visibilitychange';
}

// get current browser vendor prefix
export function getBrowserPrefix() {
  for (var i = 0; i < browserPrefixes.length; i++) {
    if(getHiddenPropertyName(browserPrefixes[i]) in document) {
      // return vendor prefix
      return browserPrefixes[i];
    }
  }

  // no vendor prefix needed
  return null;
}

// bind and handle events
var browserPrefix = getBrowserPrefix(),
    hiddenPropertyName = getHiddenPropertyName(browserPrefix),
    visibilityEventName = getVisibilityEvent(browserPrefix);

export function handleVisibilityChange(forcedFlag) {
  // forcedFlag is a boolean when this event handler is triggered by a
  // focus or blur eventotherwise it's an Event object
  if(typeof forcedFlag === "boolean") {
    if(forcedFlag) {
      if (exist(onVisible) ){
      	return onVisible();
      }
    }

	if (exist(onHidden) ){
    		return onHidden();
    	}
  }

  if(document[hiddenPropertyName]) {
    if (exist(onHidden) ){
    		return onHidden();
    	}
  }

  if (exist(onVisible) ){
  	return onVisible();
  }

  return null;
}

document.addEventListener(visibilityEventName, handleVisibilityChange, false);

// extra event listeners for better behaviour
document.addEventListener('focus', function() {
  handleVisibilityChange(true);
}, false);

document.addEventListener('blur', function() {
  handleVisibilityChange(false);
}, false);

window.addEventListener('focus', function() {
    handleVisibilityChange(true);
}, false);

window.addEventListener('blur', function() {
  handleVisibilityChange(false);
}, false);

// - Popups ------------------------------------------
var popups = {};
export function popup( _url, _name, _w, _h ){
    if( !popups[_name] || popups[_name].closed ){
        //create new
        popups[_name] = window.open( _url, _name, 'scrollbars,resizable,height='+_h+',width='+_w );
    } else {
        //it exists, load new content (if necs.)
        popups[_name].location.href = _url;
        //give it focus (in case it got burried)
        popups[_name].focus();
    }
}

export function updatePopupsLinks( _element ){
  _element.find('a.pop-up').on('click', function() {
    var url = $(this).attr('href');
    var w = $(this).attr('w') || 300;
    var h = $(this).attr('h') || 300;
    // -
    popup( url, w, h );
    return false;
  });
}

//- Strings
export function removeHTMLtags( str ){
  return str.replace(/<[^>]*>/g, "");
}
export function removeHTMLtagsEscaped( str ){
  str = str.replace(/&quot;|&lt;[a-z/]*&gt;/gi, "");
  return str;
}
export function removeBR( str, _replace ){
  _replace = _replace || "\r";
  return str.replace(/\[\[br\]\]/g, _replace );
}
export function replaceCometes( str ){
  _replace = '”';
  return str.replace(/\"/g, _replace );
}
export function deentitize( str ){
  var ret = str.replace(/&gt;/g, '>');
  ret = ret.replace(/&lt;/g, '<');
  ret = ret.replace(/&quot;/g, '"');
  ret = ret.replace(/&apos;/g, "'");
  ret = ret.replace(/&amp;/g, '&');
  return ret;
}


//- Arrays =======================================================
Array.prototype.move = function(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

export function getElementFromArrayById( _array, _id ){
  var element; 
  $.each( _array, function( _index, _el ){
      var t = _el;
      if( t.id === _id ){
        element = t;
      }
  });

  // -
  if( element ){
      return element;
  }else{
      return false;
  }
}

export function getElementFromArrayByVar( _array, _var, _val ){
  var element; 
  $.each( _array, function( _index, _el ){
      var t = _el;
      if( t[_var] === _val ){
        element = t;
      }
  });

  // -
  if( element ){
      return element;
  }else{
      return false;
  }
}

//- Mezclar un Array
export function shuffleArray( array, noRepeat ) {
  for (var i = array.length - 1; i >= 0; i--) {
      var j, k,
      maxIntentos = noRepeat ? 25 : 1, /* Intentamos n veces que no se repita el resultado. Sino, damos por hecho que no es posible */
      temp = array[i],
      next = array[i+1];
      
      Intentos:
      for( k=0; k < maxIntentos; k++){
        j = Math.floor(Math.random() * (i + 1));
        if( noRepeat && (next && next !== array[j]) ){
          break Intentos;
        }
      }
      array[i] = array[j];
      array[j] = temp;
  }
  return array;
}

//-
export function pushArrayUnique( _array, _val ){
  $.each( _array, function( _index, _el ){
    if( _el === _val ){
      return false;
    }
  });
  //- else…
  _array.push( _val );
}

//- Object =====================================================
export function pushObjectUnique( _object, _key, _val ){
  if( !_object[_key] ){
    _object[_key] = _val;
  }else{
    return false;
  }
}
export function objectAllPropsFull( _object ){
  var result = true;
  $.each( _object, function( _key, _val ){
    if( !_val || _val === ''){
      result = false;
    }
  });
  //- else…
  return result;
}

export function withoutZeros( _str ){
  return _str.replace(/^0+/, '');
}


// - Math =======================================================
export function rand(min, max){
  return min + (Math.random() * (max-min));
}

export function randFloor(min, max){
  return Math.floor(Math.random() * (max - min + 1)) + min;
}


// - Traductor =======================================================
export function trad( str1, str2, str3 ){
  switch( lang ){
    case 'es':
      return str2 || str1;
    case 'en':
      return str3 || str2 || str1;
    default:
      return str1;
  }
}


//- Empty images
export const emptySrc335 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAU8AAADKCAQAAACPzgqmAAABgUlEQVR42u3SAQ0AAAzCsOPf9G1A0kpYloNakQB7gj2xJ9gT7Ik9wZ7YE+wJ9sSeYE/sCfYEe2JPsCf2BHuCPbEn2BN7gj3BntgT7Ik9wZ5gT+wJ9sSeYE+wJ/YEe2JPsCfYE3uCPbEn2BPsiT3BntgT7An2xJ5gT+wJ9gR7Yk+wJ/YEe4I9sSfYE3uCPcGe2BPsiT3BnmBP7An2xJ5gT7An9gR7Yk+wJ9gTe4I9sSfYE+yJPcGe2BPsCfbEnmBP7An2BHtiT7An9gR7gj2xJ9gTe4I9wZ7YE+yJPcGeYE/sCfbEnmBPsCf2BHtiT7An2BN7gj2xJ9gT7Ik9wZ7YE+wJ9sSeYE/sCfYEe2JPsCf2BHuCPbEn2BN7gj3BntgT7Ik9wZ5gT+wJ9sSeYE+wJ/YEe2JPsCfYE3uCPbEn2BPsiT3BntgT7An2xJ5gT+wJ9gR7Yk+wJ/YEe4I9sSfYE3uCPcGe2BPsiT3BnmBP7An2xJ5gT7An9gR7Yk+wJ9iTfQ8KKwDLVq/sHQAAAABJRU5ErkJggg==';
export const emptySrc250 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAACWCAQAAABd012XAAAA+UlEQVR42u3RMQEAAAzCsOHf9FRwkUpocporFkAXdEEXdEEXdEEXdEEXdEEXdEEXdEEXdEGHLuiCLuiCLuiCLuiCLuiCLuiCLuiCLuiCDl3QBV3QBV3QBV3QBV3QBV3QBV3QBV3QBR26oAu6oAu6oAu6oAu6oAu6oAu6oAu6oAs6dEEXdEEXdEEXdEEXdEEXdEEXdEEXdEEXdOgWQBd0QRd0QRd0QRd0QRd0QRd0QRd0QRd0QYcu6IIu6IIu6IIu6IIu6IIu6IIu6IIu6IIOXdAFXdAFXdAFXdAFXdAFXdAFXdAFXdAFHbqgC7qgC7qgC7qgC7qgC7o6Pb4IAJe5qBzgAAAAAElFTkSuQmCC';