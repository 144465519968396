import { get_browser, detectMobile, detectIOS, getQueryVariable, exist, trad, emptySrc335, emptySrc250 } from './functions'

//- Resolve $ error in WP
window.$ = window.jQuery;

// -
let bodyEl = null;
//-
let nothing = function(e){};// para iniciar funciones inasignadas sin dar error


let browser = get_browser();
//-
let isTouch = false;
let isOnline = false; // navigator.onLine;

//-
let nVer 	= navigator.appVersion,
    OS 		= "Unknown OS",
    isWin10;
//-

let doc, user, isMobile, testMode, isiOS, iOSversion, isiPad, isiPhone, noInteract;

//-
let win;
let WIDTH = 1200,
    contentWIDTH = WIDTH,
    mobileWidth = false,
    HEIGHT = 1000,
    isLandscape,
    dateFormat = "YYYY-MM-DD";

//- Init
let onResize = nothing, pasaSlide = nothing, updateSlideHome = nothing, autoSlideHome = nothing, autoSlideHomeFu = nothing, scrollFu = nothing, updateSlideHomeAfterScroll = nothing;

let bgColor = 'bgColor',
    bookmarks = {ids: []};

//-
let withActivityDays = [];
//-


(function ($, Drupal) {
    'use strict';
    
    // To understand behaviors, see https://drupal.org/node/756722#behaviors
    Drupal.behaviors.my_custom_behavior = {
        attach: function (context, settings) {            
            let DOMURL = window.URL || window.webkitURL || window;
            //-
            doc = document.documentElement;
            win = $(window);
            bodyEl = document.getElementsByTagName("html")[0];
            //-
            WIDTH = window.innerWidth;
            HEIGHT = window.innerHeight;
            //*/

            isOnline = (document.location.hostname !== "localhost" && document.location.hostname !== "");
            isTouch = typeof window.ontouchstart !== 'undefined';
            if (isTouch) bodyEl.classList.add('isTouch');

            //- Add OS as an attribute
            doc.setAttribute('data-useragent', navigator.userAgent);
            

            // -
            if ( nVer.indexOf("Win") !== -1 ){ 	
                OS = "win"; 
                bodyEl.classList.add('isWin'); 
            }
            if ( nVer.indexOf("Mac") !== -1 ){  
                OS = "mac"; 
                bodyEl.classList.add('isMac'); 
            }
            if ( nVer.indexOf("Windows NT 10") !== -1 ){ 
                isWin10 = true; 
                bodyEl.classList.add('isWin10'); 
            }

            // -
            if( getQueryVariable("test") !== false )        testMode = true;
            if( getQueryVariable("isMobile") !== false )    isMobile = true;
            if( getQueryVariable("no") !== false )          noInteract = true;
            if( getQueryVariable("ios") !== false ){
                                                            isMobile = true;
                                                            isiOS = true;
            }
            //-
            isMobile = detectMobile();
            if (isMobile) bodyEl.classList.add('isMobile');
            // -
            isiOS = detectIOS();
            if (isiOS) bodyEl.classList.add('isiOS');
            //-
            if( testMode ) bodyEl.classList.add('testMode');

            //- RESIZE ---------------------------------------------------------------------------------------------
            onResize = function(){
                WIDTH = window.screen.availWidth;
                mobileWidth = WIDTH <= 576;
                //-
                HEIGHT = window.screen.availHeight;
                //-
                isLandscape = WIDTH > HEIGHT ? true : undefined;

            };
            onResize();

            
            // - Menu
            function closeExpandemenus(){
                console.log( $('.header li.expanded.opened').length );
                $('.header li.expanded.opened').each( function( _index, _el ){
                    $(_el).removeClass('opened');
                    $(_el).find('ul.menu').css('height', '0px');
                });
            }

            //- Burger
            let burgerDom = '<div class="burger">' +
                '<svg xmlns="http://www.w3.org/2000/svg" width="155.56" height="152.85" viewBox="0 0 155.56 152.85">' +
                '<g id="lines">' +
                    '<line x1="8.78" y1="23.92" x2="146.78" y2="23.92" />' +
                    '<line x1="8.78" y1="76.42" x2="146.78" y2="76.42" />' +
                    '<line x1="8.78" y1="128.93" x2="146.78" y2="128.93" />' +
                '</g>' +
                '<g id="cross">' +
                    '<line x1="8.78" y1="9.06" x2="146.78" y2="143.78" />' +
                    '<line x1="8.78" y1="144.07" x2="146.78" y2="8.78" />' +
                '</g>' +
                '</svg>' +
            '</div>'; 
            // -
            let domCloseX = '<svg id="close_svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="50" viewBox="0 0 64 64">'+
                '<g id="cross">' +
                    '<line x1="5" y1="5" x2="50" y2="50" />' +
                    '<line x1="5" y1="50" x2="50" y2="5" />' +
                '</g>' +
            '</svg>';


            $('.header').prepend(burgerDom).attr('title', 'Menu');
            // -
            $('.header .burger').bind('click', function(e){
                $('.header ul.menu li').each( function( _index, _el ){
                    $(this).css({'transition-duration': (0.4+(Math.random()*0.4))+'s'});
                });
                $('.header').toggleClass('opened');
                closeExpandemenus();
            });

            /* - Personatges
            let personatgesMenu = '<div id="personatges">'+
                '<img src="'+imgpath+'personatges/img-menu-col1.png" />'+
                (mobileWidth ? '' :
                    '<img src="'+imgpath+'personatges/img-menu-col2.png" />'+
                    '<img src="'+imgpath+'personatges/img-menu-col3.png" />') +
            '</div>';
            $('.header #block-system-main-menu').append(personatgesMenu);
*/
            
            // - DAUS
            let personatgesMenu = '<div id="personatges">'+
            '<div id="img-menu-col-1"></div>'+
            (mobileWidth ? '' :
                '<div id="img-menu-col-2"></div>'+
                '<div id="img-menu-col-3"></div>') +
            '</div>';
            $('.header #block-system-main-menu').append(personatgesMenu);
        
                    
            
            // - Menu desplegables
            if( mobileWidth ){
                $('.header li.expanded').each( function( _index, _el ){
                    $(_el).bind('click', function(e){
                        closeExpandemenus();
                        if( !$(_el).hasClass('opened') ){
                            let menuEl = $(_el).find('ul.menu');
                            menuEl.css('height', 'auto');
                            let hMenu = Math.ceil($(_el).height())-25;
                            menuEl.css('height', '0px');
                            setTimeout( function(){
                                menuEl.css('height', hMenu+'px');
                                $(_el).toggleClass('opened');
                            }, 16 );
                        }
                    });
                });
            }



            // - Background-images
            let bgBody = '';

            // - HOME
            //console.log( nid );
            if(nid){
                switch( nid ){
                    case 'home':
                        bgBody +=   '<div id="xemeneia" data-src="'+imgpath+'xemeneia.png"></div>';
                        // -

                        // - Personatge random
                        let personatgesHome = ['nen_dau_blau', 'noia_dau_blau', 'noi_ipad_blau', 'noia_dau2_blau', 'nena_dau_blau', 'nena_cano_blau', 'noia_ipad_blau'];
                        let personatgesHomeId = Math.floor(Math.random() * personatgesHome.length);
                        $('#random-ilustration').css('background-image', 'url('+imgpath+'personatges/'+personatgesHome[personatgesHomeId]+'.png)');
                        break;
                }
            }
            // -
            $('#bg-body').append(bgBody);

            // -
			$('[data-src]').each( function( _index, _el ){
                let src = $(_el).attr('data-src');
				let image = new Image();
				image.onload = function(){
					$(_el).css( 'background-image', 'url('+this.src+')' );
				};
				image.src = src;
            });


            // - Escape
            if( $('.escape .ilustration').length > 0 ){
                // -
                window.closeEscape = function(){
                    setTimeout( function(){
                        $('.escape .ilustration').removeClass('fixed');
                    }, 200 );
                };
                // -
                $('.escape .ilustration').prepend("<button class='close' onClick='closeEscape()'>"+domCloseX+"</button>");
                // -
                $('.escape .ilustration').on('click', function(e){
                    $(this).addClass('fixed');
                });
            }


            //-
            win.resize(onResize);
            
            //-
            $('body > #page').addClass('loaded');

            //-
            onResize();
        }
    };
})(jQuery, Drupal);